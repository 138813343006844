import React from 'react'
import { Timeline } from 'antd';

export const contact = {
    email: 'farebnystvorlistok@gmail.com',
    phone: '0904 517 724',
    adress: 'Brodňanská 225/80, 01014 Žilina-Brodno',
    facebook: 'OZ Farebný štvorlístok'
}

const Contact = () => {
    return (
        <div className='content'>
            <h1 style={{marginBottom: '28px'}}>Kontakt</h1>
            <div>
                <Timeline
                    items={[
                      {
                        color: 'green',
                        children: <span><strong>Email:</strong><br/>{contact.email} </span>,
                      },
                      {
                        color: 'green',
                        children: <span><strong>Mobil:</strong><br/>{contact.phone} </span>,
                      },
                      {
                        color: 'green',
                        children: <span><strong>Adresa:</strong><br/>{contact.adress} </span>,
                      },
                      {
                        color: 'green',
                        children: <span><strong>Facebook:</strong><br/><a target={'_blank'} href={'https://www.facebook.com/groups/httpswww.farebnystvorlistok.sk'}>{contact.facebook} </a></span>,
                      },
                    ]}
                />
            </div>
        </div>
    )
}

export default Contact