const version = '1.2.0'

export const TEXT = {
    PAGE_NAME: 'Farebný štvorlístok',
    IBAN: 'SK48 8330 0000 0021 0186 8852',
    BANK: 'FIO banka',
    COPYRIGHT: `© 2025 farebnystvorlistok.sk ｜ v.${ version }`,
}

export const STATIC_ROUTES = {
    CONTACTS: '/kontakt',
    SPONSORS: '/sponzori',
    ACTIVITIES: '/aktivity',
    ABOUT: '/o-nas',
    PUBLICATION: '/zverejnovanie',
    DEFAULT:'/',
    TWO_PERCENT:'/2percenta'
}

export const MENU = [
    { 
        TEXT: 'Prečo sme vznikli',  
        PATH: STATIC_ROUTES.ABOUT
    }, {
        TEXT: 'Galéria',
        PATH: STATIC_ROUTES.ACTIVITIES
    }, {
        TEXT: 'Kontakt',
        PATH: STATIC_ROUTES.CONTACTS
    }/*, {
        TEXT: 'Zverejňovanie',
        PATH: STATIC_ROUTES.PUBLICATION
    }*/, {
        TEXT: 'Darujte nám 2%',
        PATH: STATIC_ROUTES.TWO_PERCENT
    }
]


