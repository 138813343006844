import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import HttpsRedirect from 'react-https-redirect'

ReactDOM.render(
  <HttpsRedirect>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </HttpsRedirect>,
  document.getElementById('root')
)
