import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import './styles/App.css';
import Header from './layout/Header/Header'
import Footer from './layout/Footer/Footer'
import About from './pages/AboutPage/AboutPage'
import TwoPercent from './pages/TwoPercent/TwoPercent'
import Actions from './pages/ActionsPage/ActionPage'
import Publication from './pages/PublicationPage/PublicationPage'
//import Sponsors from './pages/SponsorsPage/SponsorsPage'
import Contact from './pages/ContactPage/ContactPage'
import { STATIC_ROUTES } from './utils/enums'
class App extends Component {
  render () {
    return (
      <div className="App">
        <Header />
        <Switch> 
          <Route path={ STATIC_ROUTES.CONTACTS } exact component={Contact}/>
          {/*<Route path={ STATIC_ROUTES.SPONSORS } exact component={Sponsors}/>*/}
          <Route path={ STATIC_ROUTES.ACTIVITIES } exact component={Actions}/>
          <Route path={ STATIC_ROUTES.PUBLICATION } component={Publication}/>
          <Route path={ STATIC_ROUTES.ABOUT } exact component={About}/>
          <Route path={ STATIC_ROUTES.TWO_PERCENT } exact component={TwoPercent}/>
          <Route path={ STATIC_ROUTES.DEFAULT } exact component={About}/>
          <Route path={ STATIC_ROUTES.DEFAULT } component={About}/>
        </Switch>
        <Footer />
      </div>
    )
  }
}

export default App;
