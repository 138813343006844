import React from 'react'
import { map } from 'lodash'
import new1 from '../../assets/images/new1.jpg'
import new2 from '../../assets/images/new2.jpg'
import new3 from '../../assets/images/new3.jpg'
import new4 from '../../assets/images/new4.jpg'
import new5 from '../../assets/images/new5.jpg'
import new6 from '../../assets/images/new6.jpg'
import new7 from '../../assets/images/new7.jpg'
import new8 from '../../assets/images/new8.jpg'
import new9 from '../../assets/images/new9.jpg'
import new10 from '../../assets/images/new10.jpg'
import new11 from '../../assets/images/new11.jpg'
import new12 from '../../assets/images/new12.jpg'
import new13 from '../../assets/images/new13.jpg'
import field from '../../assets/images/ihrisko.jpg'
import field2 from '../../assets/images/ihrisko1.jpg'
import field3 from '../../assets/images/ihrisko2.jpg'
import field4 from '../../assets/images/ihrisko3.jpg'
import field5 from '../../assets/images/ihrisko4.jpg'
import field6 from '../../assets/images/ihrisko5.jpg'
import field7 from '../../assets/images/ihrisko6.jpg'
import pic3 from '../../assets/images/pic3.jpg'
import pic5 from '../../assets/images/pic5.jpg'
import sol from '../../assets/images/sol.jpg'
import sol1 from '../../assets/images/sol2.jpg'
import so3 from '../../assets/images/sol3.jpg'
import sol4 from '../../assets/images/sol4.jpg'
import sol5 from '../../assets/images/sol5.jpg'
import styled from 'styled-components'
import{ Image,Divider }from"antd"

const images = [new1,new2,new3,new4,new5,new6,new7,new8,new9,new10,new11,new12,new13,field,field2,field3,field4,field5,field6,field7,pic3,pic5,sol,sol1,so3,sol4,sol5]

const Galery = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`

const Actions = () => {

    return (
        <div className='content'>
            <Divider style={{ borderColor: '#7cb305' }}>Aktivity</Divider>
                <p style={{ textAlign: 'justify' }}>
                V spolupráci s pracovníkmi <b>Mestského úradu Žilina</b> a pani poslankyňou <b>Mgr. et Mgr. Janou Filipovou</b> vzniklo v Žiline na sídlisku Vlčince prvé 
                bezbariérové inkluzívne ihrisko.
                </p>
                <Divider/>

                <p>Mestský úrad v Žiline odbor sociálnych vecí nám poskytol bezbarierový priestor. Od novembra 2021 sa stretávame každú <b>stredu 9-12 hod.</b> a <b>štvrtok 13-17 hod.</b> v dennom centre Solinky na Borovej ulici v Žiline.
                Centrum slúži pre deti aj pre rodičov a opatrovateľov. <b>Poskytujeme tiež poradenstvo pri komunikácii s úradmi.</b></p>

            <br/>
            <Galery>
                {map(images, (item, key) => (
                    <Image key={key} height={250} src={item} preview={"Zobraziť"}/>
                ))}
            </Galery>
            <br/>
        </div>
    )
}

export default Actions