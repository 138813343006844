import React from 'react'
import Vyhalsenie from '../../assets/files/vyhlasenie2.pdf'
import frame from '../../assets/images/frame.png'
import { Tooltip, Button } from 'antd';


const TwoPercent = () => {
    return (
        <div className='content'>
            <h3 style={{marginBottom: '28px'}} >Darujte nám 2% z daní a pomôžte nám podporiť deti so zdravotným znevýhodnením! </h3>
            <p>
            Pridajte sa k nám a venujte 2 % zo svojich daní na pomoc deťom, ktoré potrebujú našu podporu. Vďaka vašim darom môžeme zabezpečiť lepšie terapeutické služby, prispieť k zlepšeniu ich kvality života a pomôcť im rozvíjať ich potenciál. Každé vaše rozhodnutie venovať časť svojich daní je významným krokom k lepšiemu životu týchto detí. Spoločne môžeme vytvoriť silnejší a štedrejší svet!</p>
            <div style={{display: 'flex', width: '100%', justifyContent: 'center', paddingBottom: '32px'}}><Button onClick={() => {
                window.open(Vyhalsenie)
            }}>Stiahnuť - vyhlásenie 2%</Button></div>
            <div style={{display: 'flex', width: '100%', justifyContent: 'center', paddingBottom: '32px'}}>
            <Tooltip placement="bottom" title={"Otvoriť dokument s vyhlásením"}>
                <img 
                    src={frame}
                    style={{background: 'black', width: '350px', height: '350px', cursor:"pointer"}}
                    onClick={() => {window.open(Vyhalsenie)}}
                />
            </Tooltip>
            </div>
        </div>
    )
}

export default TwoPercent